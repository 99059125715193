<template>
  <div class="usermessage">
    <!-- 导航栏 -->
    <van-nav-bar
      title="Notification messages"
      :class="!$isLinkGame ? 'black-bc yellow-color' : 'red-bc white-color'"
    >
      <i
        slot="left"
        class="iconfont icon-jiantou"
        @click="$router.back()"
        :color="!$isLinkGame ? '#ffd400' : '#fff'"
      ></i>
      <template #title>
        <span :class="!$isLinkGame ? 'yellow-color' : 'white-color'"
          >Notification messages</span
        >
      </template>
    </van-nav-bar>
    <van-cell :title="title" @click="isshowDialog = true">
      <i
        slot="right-icon"
        class="iconfont icon-zelvxuanzefeiyongdaosanjiaoxingfandui"
      ></i>
    </van-cell>
    <!-- 弹出框区域 -->
    <van-dialog
      v-model="isshowDialog"
      show-cancel-button
      confirm-button-text="Confirm"
      cancel-button-text="CANCEL"
      confirm-button-color="#00aa88"
      cancel-button-color="#00aa88"
      @confirm="HanddleConfirm"
    >
      <van-radio-group v-model="radio" @change="HanddleRadioChange">
        <van-cell-group>
          <van-cell title="All messages" clickable @click="radio = '1'">
            <template #right-icon>
              <van-radio checked-color="#00aa88" name="1" />
            </template>
          </van-cell>
          <van-cell title="Unread message" clickable @click="radio = '2'">
            <template #right-icon>
              <van-radio checked-color="#00aa88" name="2" />
            </template>
          </van-cell>
        </van-cell-group>
      </van-radio-group>
    </van-dialog>
  </div>
</template>

<script>
import { usersmessage } from '@/api/use.js'
export default {
  name: 'Usermessages',
  components: {},
  data() {
    return {
      isshowDialog: false, // 控制弹出框是否显示
      radio: '',
      title: 'All messages',
      pages: 1,
      status: 'all',
    }
  },
  mounted() {},
  created() {
    //   TODO:
    //   this.getUserMessage()
  },
  methods: {
    // 当单选框发生改变的时候触发
    HanddleRadioChange(value) {
      if (value === '1') {
        this.title = 'All messages'
        this.status = 'all'
      } else {
        this.title = 'Unread message'
        this.status = 'unread'
      }
    },

    // 点击确认按钮时触发的函数
    HanddleConfirm() {
      // TODO:
      //    this.getUserMessage()
    },

    // 获取信息
    async getUserMessage() {
      const res = await usersmessage({
        status: this.status,
        page: this.pages,
      })
      console.log(res)
    },
  },
}
</script>

<style lang="less" scoped>
.usermessage {
  /deep/ .van-nav-bar__content {
    // background-color: #ff0000;
    /deep/ .van-nav-bar__title,
    .van-nav-bar__right {
      // color: #fff;
    }

    .iconfont {
      font-size: 44px;
      // color: #fff;
    }
  }
  .van-dialog {
    height: 300px;
    border-radius: 0;
    .van-cell {
      height: 100px;
      .van-radio {
        margin-right: 10px;
      }
    }
  }
}
</style>
